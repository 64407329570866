import { Link } from "gatsby"
import React from "react"
import yolcu from "../assets/img/companys/01.png"
import cetmen from "../assets/img/companys/02.png"
import ecanta from "../assets/img/companys/03.png"
import enza from "../assets/img/companys/04.png"
import konfor from "../assets/img/companys/05.png"
import kuhne from "../assets/img/companys/06.png"
import matbuu from "../assets/img/companys/07.png"
import oracle from "../assets/img/companys/08.png"
import pakmaya from "../assets/img/companys/09.png"
import robesnmore from "../assets/img/companys/10.png"
import sefastone from "../assets/img/companys/11.png"
import yatas from "../assets/img/companys/12.png"
import ttgv from "../assets/img/companys/14.png"
import tcdd from "../assets/img/companys/15.png"
import sierra from "../assets/img/companys/16.png"
import liva from "../assets/img/companys/17.png"
import jll from "../assets/img/companys/18.png"
import popupsmart from "../assets/img/companys/20.png"
import arcelik from "../assets/img/companys/21.png"
import stm from "../assets/img/companys/22.png"
import havelsan from "../assets/img/companys/23.png"
import tanoto from "../assets/img/companys/26.png"
import cagdas from "../assets/img/companys/24.png"
import cumhurb from "../assets/img/companys/32.png"
import unwomen from "../assets/img/companys/31.png"
import toyota from "../assets/img/companys/toyota-logo.png"






const Companys = () => (
    <div className="company-component">
        <div className="company-box">
            <img src={toyota} />
        </div>
        <div className="company-box">
            <img src={oracle} />
        </div>
        <div className="company-box">
            <img src={unwomen} />
        </div>
        <div className="company-box">
            <img src={robesnmore} />
        </div>
        <div className="company-box">
            <img src={popupsmart} />
        </div>
        <div className="company-box">
            <img src={cumhurb} />
        </div>
        <div className="company-box">
            <img src={havelsan} />
        </div>
        <div className="company-box">
            <img src={stm} />
        </div>
        <div className="company-box">
            <img src={arcelik} />
        </div>
        <div className="company-box">
            <img src={yolcu} />
        </div>
        <div className="company-box">
            <img src={enza} />
        </div>
        <div className="company-box">
            <img src={tcdd} />
        </div>
        <div className="company-box">
            <img src={konfor} />
        </div>
        <div className="company-box">
            <img src={kuhne} />
        </div>
        <div className="company-box">
            <img src={matbuu} />
        </div>
        <div className="company-box">
            <img src={cetmen} />
        </div>
        <div className="company-box">
            <img src={sefastone} />
        </div>
        <div className="company-box">
            <img src={yatas} />
        </div>
        <div className="company-box">
            <img src={pakmaya} />
        </div>
        <div className="company-box">
            <img src={ttgv} />
        </div>
       
        <div className="company-box">
            <img src={ecanta} />
        </div>
        
        <div className="company-box">
            <img src={sierra} />
        </div>
        <div className="company-box">
            <img src={liva} />
        </div>
        <div className="company-box">
            <img src={jll} />
        </div>
        <div className="company-box">
            <img src={cagdas} />
        </div>
        <div className="company-box">
            <img src={tanoto} />
        </div>
        
        




    </div>
)

export default Companys
